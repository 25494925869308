<template>
    <div>
        <b-form-group label="Company Letter Head" class="">
            <b-media class="mb-2 mt-2" @click="$refs.refInputEl.click()">
                <template #aside>
                <b-avatar
                    ref="company_logo"
                    :src="companyLetterHead.image != '' ?companyLetterHead.image : doc_icon"
                    variant="light-primary"
                    size="200px"
                    rounded
                />
                </template>

                <div class="d-flex flex-wrap">
                <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="companyLetterHeadRenderer"
                >
                </div>
            </b-media>
        </b-form-group>


        <b-modal id="crop-modal" ref="crop-modal" centered title="Crop Company Letter Head" hide-footer :no-close-on-backdrop="true" @hide="resetModal">
      
        <template>
              <div>
                
                <div class="">
                  <section class="cropper-area">

                    <div class="img-cropper">

                     <vue-cropper ref="cropper" :src="temp.image" /> <!-- :aspectRatio="0.707"  removed for freeform selection-->
                      
                    </div>

                    <div class="pt-2 text-center float-left">
                      
                      <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>

                      <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>

                    </div>
                  </section>
                </div>
              </div>
        </template>
     
    </b-modal>

    </div>
</template>

<script>
import {
    BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile
} from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import Bus from "../../../event-bus";
import { POST_API } from "../../../store/actions.type"

export default{
    name: 'CompanyLetterHead',
    components: {
        BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, BFormFile
    },
    props: ['companyLetterHead'],
    data(){
        return{
            doc_icon: require('@/assets/images/doc.png'),
            temp:{
                image:null,
                name:'',
            },
        }
    },
    methods: {
        companyLetterHeadRenderer(event){
            var input = event.target;
            var files = event.target.files

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var image = input.files[0];
                //console.log(image);

                if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                    
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please upload .png, .jpeg, .jpg images only',
                        showConfirmButton: false,
                        timer: 1500
                        })
                    this.$refs.refInputEl.value=null;

                } else if (image.size > 2097152) {

                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                        })
                    this.$refs.refInputEl.value=null;
                } else {
                    reader.onload = (e) => {
                        this.temp.image = e.target.result;
                        this.temp.name = image.name;
                        this.modelbox = 'companyLetterHead';

                        this.showImg();
                    }
                }
            }
        },

        showImg(){
            this.$refs['crop-modal'].show();
        },

        resetModal(){
            this.$refs.refInputEl.value=null;            

            this.temp.image = null;
            this.temp.name="";

            this.$refs['crop-modal'].hide();
        },

        cropImage() {
            this.companyLetterHead.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.companyLetterHead.name = this.temp.name;

            Bus.$emit('update-company-letter-head', {data: this.companyLetterHead});
            
            this.$refs['crop-modal'].hide(); 
        },
    },
    mounted(){
        // console.log(this.companyLetterHead);
    }
}
</script>